<template>
  <v-container fluid style="padding:0!important">

    <!-- top toolbar -->
    <v-toolbar>
      <v-icon>mdi-clipboard-edit-outline</v-icon>
      <v-spacer></v-spacer>
      <!-- print  -->
      <v-menu v-model="print_model" :close-on-content-click="false" offset-x offset-y right>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-shortkey="['ctrl','alt','p']" v-bind="attrs" v-on="on" text outlined class="blue darken-2" elevation="4">
            <v-icon color="white">mdi-cloud-print-outline</v-icon>
          </v-btn>
        </template>
        <v-card max-width="300" elevation="4" width="300" outlined>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-select v-model="select_columns" :items="print_columns" label="Select Columns" multiple hint="Pick columns to print" persistent-hint class="caption">
                  <template v-slot:selection="{ item, index }">
                    <v-chip small v-if="index === 0"> <span>{{ item }}</span> </v-chip>
                    <span v-if="index === 1" class="grey--text text-caption">(+{{ select_columns.length - 1 }} others)</span>
                  </template>
                </v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-btn small :dark="!(select_columns.length==0)" block color="blue darken-2" @click="print()"
                  :disabled="select_columns.length==0">Print</v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-menu>
      <!-- excel  -->
      <v-tooltip top open-delay="500">
        <template v-slot:activator="{ on, attrs }">
          <v-btn text outlined class="blue darken-2 ml-2" elevation="4" v-shortkey="['ctrl','alt','e']" v-bind="attrs"
            v-on="on">
            <v-icon color="white">mdi-microsoft-excel</v-icon>
          </v-btn>
        </template>
        <span>Export Formula to Excel</span>
      </v-tooltip>
    </v-toolbar>

    <!-- panels -->
    <v-expansion-panels v-model="panels" accordion multiple focusable :disabled="panel_disabled">
      <!-- formulation panel -->
      <v-expansion-panel key="formulation">
        <v-expansion-panel-header class="text-uppercase font-weight-bold">
          Formulation
          <span class="ml-1 caption">- created by {{base_item.first}} {{base_item.last}} on {{base_item.created}}
            <v-icon v-if="base_item.archive_id!=null" class="ml-2 blue--text text--darken-2">mdi-archive-outline</v-icon>
            <v-icon v-if="base_item.isDuplicate" class="ml-2 blue--text text--darken-2">mdi-content-duplicate</v-icon>
          </span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row class="mt-1">
            <v-col cols="5">
              <v-text-field disabled spellcheck v-model="base_item.description" label="Description" placeholder="Formula Description" maxlength="75" counter="75" hide-details></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field disabled spellcheck v-model="base_item.company" label="Company" placeholder="Company" maxlength="35" counter="35" hide-details></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-text-field disabled v-model="base_item.created" label="Created" placeholder="Created" hide-details></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-text-field readonly v-model="base_item.number" label="Number" placeholder="Formula Number" hide-details>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3">
              <v-select disabled v-model="category_tags" :items="category_items" hide-details ref="category" dense></v-select>
            </v-col>
            <v-col cols="6">
              <v-select disabled v-model="formula_keywords" :items="keyword_items" hide-details multiple ref="subcategory" dense></v-select>
            </v-col>
            <v-col cols="3">
              <v-select disabled hide-details v-model="processing_rules" dense></v-select>
            </v-col>
          </v-row>
          <!-- second row -->
          <v-row>
            <v-col cols="3">
              <v-text-field v-model="base_item.brix" maxlength="6" counter="6" disabled :hint="base_item.brix_hint" label="Brix"></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field v-model="base_item.density" label="Density g/L" disabled placeholder="Density g/L" maxlength="10" counter="10"></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field disabled v-model="base_item.ph" label="pH" placeholder="pH" maxlength="6" counter="6" required></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field disabled v-model="base_item.acidity" label="Titratable Acidity %" placeholder="Titratable Acidity" maxlength="6" counter="6"></v-text-field>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <!-- data table -->
      <v-expansion-panel key="details" class="panel_shrink">
        <v-expansion-panel-header class="text-uppercase font-weight-bold">
          Formulation Details
        </v-expansion-panel-header>
        <v-expansion-panel-content color="blue-grey lighten-3">
          <v-data-table id="formulation" :headers="formulation.headers" :items="formulation.items" class="elevation-3"
            :loading="formulation.loading" item-key="dbid" :disable-pagination="true" calculate-widths
            :disable-sort="formulation.custom_sorting" hide-default-footer v-model="formulation.selected"
            :footer-props="{ 'items-per-page-options': [-1] }">
            <!-- table top options row -->
            <template v-slot:top>
              <v-toolbar dense flat class="grey lighten-3">
                <v-toolbar-title>
                  <v-icon small class="mr-2">mdi-format-list-bulleted</v-icon>
                </v-toolbar-title>
                <span class="overline mr-2">Batch Size</span>
                <v-text-field v-model="batch_size" class="shrink orange--text caption" solo dense flat hide-details disabled></v-text-field>
                <v-divider class="mx-4 black" inset vertical></v-divider>
                <v-select v-model="formula_type" placeholder="Formula Type" :items="formula_type_items" solo dense flat hide-details class="shrink-select orange--text mr-2 caption" disabled></v-select>
                <v-select hide-details v-if="formula_type===1" v-model="formula_uom" solo dense flat disabled :items="formula_uom_items" placeholder="Unit of Measurement" class="shrink-select orange--text caption"></v-select>
                <v-spacer></v-spacer>
              </v-toolbar>
            </template>
            <!-- table rows -->
            <template v-slot:[`item.item`]="{ item }">
              <!-- item description field -->
              <v-text-field disabled v-model="item.item" spellcheck class="caption" maxlength="75"
                counter="75" placeholder="Item" dense flat hide-details></v-text-field>
            </template>
            <template v-slot:[`item.code`]="{ item }">
              <v-text-field disabled v-model="item.code" class="caption" maxlength="15" counter="15"
                placeholder="Code" dense flat hide-details></v-text-field>
            </template>
            
            <template v-slot:[`item.glvalue`]="{ item }">
              <v-text-field disabled v-model="item.value" class="caption" maxlength="8" counter="8"
                placeholder="Value" dense flat hide-details></v-text-field>
            </template>
            
            <!-- add comment field somehow -->
            <template v-slot:[`item.rmc`]="{ item }">
              <v-text-field disabled v-model="item.rmc" class="caption" maxlength="8" placeholder="RMC" dense flat>
              </v-text-field>
            </template>

            <template v-slot:[`item.action`]="{ item }">
              <v-icon slot="append" @click="outline_notes(item)" small color="grey">mdi-comment-outline</v-icon>
            </template>
            
            <template v-slot:[`item.batchvalue`]="{ item }">
              <v-text-field v-model="item.glvalue" class="caption" maxlength="8" counter="8" dense flat hide-details
                :value="$set(item, 'glvalue', +(item.value * batch_size).toFixed(2))" disabled solo>
              </v-text-field>
            </template>

            <template v-slot:[`item.percentformula`]="{ item }">
              <!-- IF grams/litre formula -->
              <v-text-field v-if="formula_classification === 0" v-model="item.percentformula" class="caption"
                maxlength="8" counter="8" placeholder="Percent" dense flat hide-details solo
                :value="$set(item, 'percentformula', ((item.value / base_item.density)*100).toFixed(4))" disabled>
              </v-text-field>
              <v-text-field v-else v-model="item.percentformula" class="caption" maxlength="8" counter="8"
                placeholder="Percent" dense flat hide-details solo
                :value="$set(item, 'percentformula', ((item.value / 1000)*100).toFixed(4))" disabled> </v-text-field>
            </template>

            <template v-slot:[`item.ciu`]="{ item }">
              <v-text-field disabled class="caption" maxlength="8" counter="8" dense flat hide-details
                :value="$set(item, 'ciu', ((item.rmc / 1000) * item.rmc).toFixed(2))" solo></v-text-field>
            </template>
            <!-- footer -->
            <template v-slot:[`body.append`]>
              <tr class="grey lighten-5 font-weight-bold">
                <td></td>
                <td>Totals</td>
                <td v-if="formula_classification === 0"><span :class="+total_value_column < +base_item.density ? 'green--text' : +total_value_column === +base_item.density ? 'blue--text' : 'red--text'">{{total_value_column}}</span>/{{base_item.density}}</td>
                <td v-else><span :class="total_value_column <= 1000 ? 'green--text' : 'red--text'">{{total_value_column}}</span>/1000</td>
                <td style="text-align:right!important;">{{total_rmc_column}}</td>
                <td style="text-align:right!important;">{{total_value_in_batch}}</td>
                <td style="text-align:right!important;">
                  <span :class="total_formula_percent < 100 ? 'blue--text' : total_formula_percent == 100 ? 'green--text' : 'red--text'">{{total_formula_percent}}</span></td>
                <td style="text-align:right!important;">$ {{total_cui_column}}</td>
                <td></td>
              </tr>
            </template>

          </v-data-table>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel key="mixing" class="panel_shrink">
        <v-expansion-panel-header class="text-uppercase font-weight-bold">
          MIXING INSTRUCTIONS&nbsp;<span class="caption blue--text">{{base_item.number}} -
            {{base_item.description}}</span>
        </v-expansion-panel-header>
        <v-expansion-panel-content color="blue-grey lighten-3">
          <div id="mixing">
            <editor @onFocus="focus" v-model="base_item.mixing" id="mixing"
              api-key="ttbthscmul66taoxff1xnpgtjhl7rdn8cwvdyo9mefawcskb" :init="{
                   height: 500,
                   menubar: true,
                   resize: false,
                   plugins: mce_plugins,
                   toolbar: mce_toolbar,
                  }" />
          </div>
          <v-progress-linear :indeterminate="base_item.mixing_busy" color="blue"></v-progress-linear>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel key="notes" class="panel_shrink">
        <v-expansion-panel-header class="text-uppercase font-weight-bold">
          APPLICATION NOTES&nbsp;<span class="caption blue--text">{{base_item.number}} -
            {{base_item.description}}</span>
        </v-expansion-panel-header>
        <v-expansion-panel-content color="blue-grey lighten-3">
          <v-row>
            <v-col md="12">
              <div id="notes">
                <editor @onFocus="focus" v-model="base_item.notes" id="notes" api-key="ttbthscmul66taoxff1xnpgtjhl7rdn8cwvdyo9mefawcskb" :init="{
                   height: 500,
                   menubar: true,
                   resize: false,
                   plugins: mce_plugins,
                   toolbar: mce_toolbar,
                  }" />
              </div>
            </v-col>
          </v-row>
          <v-progress-linear :indeterminate="base_item.mixing_busy" color="blue"></v-progress-linear>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel key="properties" class="panel_shrink">
        <v-expansion-panel-header class="text-uppercase font-weight-bold">
          ADDITIONAL PROPERTIES&nbsp;<span class="caption blue--text">{{base_item.number}} -
            {{base_item.description}}</span>
        </v-expansion-panel-header>
        <v-expansion-panel-content color="blue-grey lighten-3">
          <v-row>
            <v-col md="12">
              <div id="properties">
                <editor @onFocus="focus" v-model="base_item.properties" id="properties" api-key="ttbthscmul66taoxff1xnpgtjhl7rdn8cwvdyo9mefawcskb" :init="{
                   height: 500,
                   menubar: true,
                   resize: false,
                   plugins: mce_plugins,
                   toolbar: mce_toolbar,
                  }" />
              </div>
            </v-col>
          </v-row>
          <v-progress-linear :indeterminate="base_item.mixing_busy" color="blue"></v-progress-linear>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel key="archive" :disabled="base_item.archive_id===null" @click="archive_expansion_click">
        <v-expansion-panel-header class="text-uppercase font-weight-bold" disable-icon-rotate>
          ARCHIVE FORMULA BUILD
          <template v-slot:actions>
            <v-icon color="primary">mdi-information-outline</v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content color="blue-grey lighten-3">
          <v-simple-table class="mt-4" dense color="white" light>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left font-weight-bold">Description</th>
                  <th class="text-left">GPL</th>
                  <th class="text-left">Brix Value</th>
                  <th class="text-left">Brix Contributed</th>
                  <th class="text-left">PCtWW</th>
                  <th class="text-left">GPKG</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in archive_items" :key="item.name">
                  <td>{{ item.desc }}</td>
                  <td>{{ item.gpl }}</td>
                  <td>{{ item.brix }}</td>
                  <td>{{ item.brixcont }}</td>
                  <td>{{ item.pctww }}</td>
                  <td>{{ item.gpkg }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <!-- print HTML data -->
    <div id="cleanTablePrint" style="margin:auto;width:95%;position:relative" v-show="printLoading">
      <p style="text-align:center;font-weight:bold;text-decoration:underline;font-size:16pt">Experimental Applications
        Formula</p>
      <table style="margin:auto;width:80%">
        <tr>
          <td class="h-spacing-1">Description:</td>
          <td>{{base_item.description}}</td>
          <td class="h-spacing-1">Number</td>
          <td>{{base_item.number}}</td>
        </tr>
        <tr>
          <td class="h-spacing-1">Customer:</td>
          <td>{{base_item.company}}</td>
          <td class="h-spacing-1">Finished Brix:</td>
          <td>{{base_item.brix}}</td>
        </tr>
        <tr>
          <td class="h-spacing-1">Batch Size:</td>
          <td>{{batch_size}}</td>
          <td class="h-spacing-1">Formula Type:</td>
          <td>{{formula_type_items[+formula_type].text}}</td>
        </tr>
      </table>
      <table
        style="margin:auto;width:80%;font-family:'Courier New';font-size:16pt;border-collapse: collapse;margin-top:40px">
        <thead style="font-family:'Courier New';font-size:12pt;font-weight:bold;">
          <tr style="background-color:#f1f1f1">
            <td v-if="select_columns.includes('Item')">Item</td>
            <td v-if="select_columns.includes('Code')">Code</td>
            <td v-if="select_columns.includes('Value')">Value</td>
            <td v-if="select_columns.includes('Batch')">Batch</td>
            <td v-if="select_columns.includes('Percent')">% of Formula</td>
            <td v-if="select_columns.includes('RMC')">RMC</td>
            <td v-if="select_columns.includes('CIU')">$CUI</td>
          </tr>
        </thead>
        <tbody style="font-family:'Courier New';font-size:14pt;">
          <tr v-for="(item, i) in formulation.items" :key="i">
            <td v-if="select_columns.includes('Item')">{{item.item}}</td>
            <td v-if="select_columns.includes('Code')">{{item.code}}</td>
            <td v-if="select_columns.includes('Value')">{{item.value}}</td>
            <td v-if="select_columns.includes('Batch')">{{item.glvalue}}</td>
            <td v-if="select_columns.includes('Percent')">{{item.percentformula}}</td>
            <td v-if="select_columns.includes('RMC')">{{item.rmc}}</td>
            <td v-if="select_columns.includes('CIU')">{{item.cui}}</td>
          </tr>
        </tbody>
      </table>
      <table
        style="margin:auto;width:80%;font-family:'Courier New';font-size:10pt;border-collapse:collapse;margin-top:40px">
        <thead style="font-family:'Courier New';font-size:12pt;font-weight:bold;">
          <tr style="background-color:#f1f1f1">
            <td>Mixing Instructions</td>
          </tr>
        </thead>
        <tbody style="font-family:'Courier New';font-size:10pt;">
          <tr>
            <td>
              <div style="padding:5px;" v-html="base_item.mixing"></div>
            </td>
          </tr>
        </tbody>
      </table>
      <!-- <div style="clear:both;page-break-after:always;"></div> -->
      <table
        style="margin:auto;width:80%;font-family:'Courier New';font-size:10pt;border-collapse:collapse;margin-top:40px">
        <thead style="font-family:'Courier New';font-size:12pt;font-weight:bold;">
          <tr style="background-color:#f1f1f1">
            <td>Application Notes</td>
          </tr>
        </thead>
        <tbody style="font-family:'Courier New';font-size:10pt;">
          <tr>
            <td>
              <div style="padding:5px;" v-html="base_item.notes"></div>
            </td>
          </tr>
        </tbody>
      </table>
      <!-- <div style="clear:both;page-break-after:always;"></div> -->
      <table
        style="margin:auto;width:80%;font-family:'Courier New';font-size:10pt;border-collapse:collapse;margin-top:40px">
        <thead style="font-family:'Courier New';font-size:12pt;font-weight:bold;">
          <tr style="background-color:#f1f1f1">
            <td>Additional Properties</td>
          </tr>
        </thead>
        <tbody style="font-family:'Courier New';font-size:10pt;">
          <tr>
            <td>
              <div style="padding:5px;" v-html="base_item.properties"></div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- confirmation dialog component -->
    <ConfirmDlg ref="confirm" />
    <!-- notes dialog -->
    <v-dialog v-model="formula_note.modal" max-width="550">
      <v-card outlined flatd>
        <v-card-title>Line Item Notes</v-card-title>
        <v-card-text>
          <v-textarea disabled v-model="formula_note.note" outlined no-resize rows="6" max-length="900" persistent-hint :hint="formula_note.hint" counter="900"></v-textarea>
        </v-card-text>
        <v-card-actions class="grey lighten-4">
          <span class="caption">Press ESC to close window</span>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import axios from 'axios'
import Editor from '@tinymce/tinymce-vue'
import { helperMixin } from '../mixins/helper'
import { EventBus } from '../components/EventBus.js'

const PERCENT_BASIS_FORMULA = 1

export default {
  name: "FormulationViewer",
  mixins: [ helperMixin ],
  components: {
    'editor': Editor,
    ConfirmDlg: () => import('./dlgConfirm')
  },
  data: () => ({

    mce_plugins: 'advlist autolink lists link image charmap print preview anchor searchreplace visualblocks insertdatetime table paste code help wordcount spellchecker',
    mce_toolbar: 'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help',

    formula_note: {
      note: '',
      hint: '',
      modal: false,
      item: {}
    },
    processing: [
      'Hot Fill', 'Cold Fill', 'Cold Fill (Preserved)', 'Tunnel', 'UHT', 'UHT (Shelf Stable)', 'HTST', 'Retort', 'Batch Past', 'Velcorin'
    ],
    archive_items: [],
    archive_copied_items: false,
    formula_classification: null,
    formula_type: '',
    formula_type_items: [ { text: 'Grams/Litre', value: 0 }, { text: 'Percent Basis', value: 1 }],
    formula_uom: '',
    formula_uom_items: [ { text: 'Weight/W', value: 0 }, { text: 'Volume/V', value: 1 }],
    print_model: false,
    print_columns: ['Item', 'Code', 'Value', 'Batch', 'Percent', 'RMC', 'CIU'],
    select_columns: ['Item', 'Code', 'Value', 'Batch', 'Percent'],
    edit_object: {},
    printLoading: false,
    measurement_metrics: '',
    printObj: {
      id: "cleanTablePrint",
      preview: true,
      previewTitle: 'Experimental Application Formula',
      popTitle: 'Experimental Application Formula',
    },
    search: {
      single_select: true,
      selected: [],
      dialog_active: false,
      headers: [{
          text: 'Item',
          align: "left",
          sortable: true,
          value: 'text',
          class: "font-weight-bold",
          divider: true
        },
        {
          text: 'Code',
          align: "left",
          sortable: true,
          value: 'value',
          class: "font-weight-bold"
        },
        {
          text: 'Cost',
          align: "left",
          sortable: true,
          value: 'cost',
          class: "font-weight-bold"
        },
      ],
      items: [],
      search: '',
      filter: '',
      dense: true,
      busy: false,
    },
    update_button: false,
    saving_formula: false,
    batch_size: 1,
    created_by: '',
    new_formulation: false,
    query: '',
    results: [],
    resultselect: null,
    searchbusy: false,
    selectedRow: 0,
    panels: [0, 1],
    panel_disabled: false,
    base_item: {
      locked: false,
      mixing: '',
      mixing_busy: false,
      mixing_focus_copy: '',
      properties: '',
      properties_busy: false,
      properties_focus_copy: '',
      notes: '',
      notes_busy: false,
      notes_focus_copy: '',
      // tinyMCE editor windows end
      created: '',
      acidity: '',
      ph: '',
      density: '',
      brix: '',
      brix_busy: false,
      brix_hint: 'Enter Custom Brix',
      description: '',
      number: '',
    },
    formulation: {
      selected: [],
      // any ajustment to this table MUST be reflected in the CONST declarations to align column numbers up
      // until the code updates to use indexOf by adding a search
      headers: [
        { text: "Item", align: "left", sortable: false, value: "item", width: "40%" },
        { text: "Code", align: "left", sortable: false, value: "code", width: "10%" },
        { text: "Value*", align: "left", sortable: true, value: "glvalue", width: "10%" },
        { text: "RMC", align: "left", sortable: false, value: "rmc", width: "10%", divider: true },
        { text: "Batch*", sortable: false, value: "batchvalue", width: "8%" },
        { text: "Formula %", sortable: false, value: "percentformula", width: "8%" },
        { text: '$CIU/L', sortable: false, value: "ciu", width: "8%", divider: true },
        { text: '', align: 'right', sortable: false, value: "action", width: '6%' },
      ],
      items: [],
      loading: false,
      search: '',
      dense: true,
      custom_sorting: false,
    },
    idx: 0,
    loading: [],
    hint: [],
    modified: false,
    panels_movement_hook: null,
    category_tags: [],
    category_items: ['Beverage','Food','Powder Blend','Other','Uncategorized'],
    formula_keywords: [],
    keyword_items: ['Alcohol','Still Beverage','Carbonated','Coffee','Dairy','Alternative Protein','Juice','Tea','Other','Uncategorized','Flavored Spirit', 'Hard Seltzer', 'Still Cocktail', 'Sparkling Cocktail', 'Wine', 'Cider', 'Beer', 'Mixer', 'Belly Wash','Functional','Soft Drink', 'Energy', 'Sparkling', 'Cold Brew', 'Hot Brew', 'Flavored', 'With Milk', 'Latte / Cappuccino', 'Espresso', 'Sparkling'],
    processing_rules: [],
  }),
  mounted: function () {
    this.idx = this.$router.history.current.params.id ?? 0
    this.formulation.loading = true
    this.load_formula()
  },
  computed: {
    total_cui_column() {
      return this.formulation.items.map(r => +r.ciu).reduce((p, c) => p + c, 0).toFixed(4)
    },
    total_rmc_column() {
      return this.formulation.items.map(r => +r.rmc).reduce((p, c) => p + c, 0).toFixed(4)
    },
    total_value_column() {
      return this.formulation.items.map(r => +r.value).reduce((p, c) => p + c, 0).toFixed(4)
    },
    total_value_in_batch() {
      return this.formulation.items.map(r => r.glvalue).reduce((p, c) => p + c, 0).toFixed(4)
    },
    total_formula_percent() {
      return this.formulation.items.map(r => +r.percentformula).reduce((p, c) => p + c, 0).toFixed(2)
    },
  },
  methods: {
    outline_notes (item) {
      this.formula_note.hint = item.item // show the item being worked on
      this.formula_note.note = item.formula_note // copy the current note
      this.formula_note.item = item.dbid // store the item index so we can write it back out
      this.formula_note.modal = !this.formula_note.modal
    },
    archive_expansion_click(e) {
      if (this.archive_items.length === 0) {
        if (!e.target.classList.contains('v-expansion-panel-header--active')) {
          let archive_id = this.base_item.archive_id
          axios.post(`${this.$store.getters.NODEURL}/formula/archive`, {
              archive_id
            }, {
              withCredentials: true
            })
            .then((result) => {
              this.archive_items = result.data.data
            })
            .catch((err) => {
              EventBus.$emit('error', {
                message: `Save Failed - 44`
              })
            })
            .finally(() => {})
        }
      }
    },
    print() {
      var print_window = window.open('', '', 'width=1000,height=800,toolbar=0,scrollbars=0,status=0')
      print_window.document.write("<!DOCTYPE html PUBLIC \"-//W3C//DTD XHTML 1.0 Transitional//EN\" \"http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd\">")
      print_window.document.write("<html><head><link href='../static/print.css' rel='stylesheet' type='text/css'/></head>")
      print_window.document.write("<body>")
      print_window.document.write(document.getElementById('cleanTablePrint').innerHTML)
      print_window.document.write("</body></html>")
      //      print_window.print()
    },
    load_formula() {
      axios.get(`${this.$store.getters.NODEURL}/formulations/${this.idx}`, {
          query: this.query
        }, {
          withCredentials: true
        })
        .then(response => {

          let d = response.data, c = 0

          this.created_by = `${d.results.first} ${d.results.last}`
          // user provided formula type
          this.formula_type = d.results.formula_type
          this.formula_uom = d.results.formula_type === PERCENT_BASIS_FORMULA ? d.results.unit_of_measurement : null
          // set the batch size
          this.batch_size = d.results.batch_size
          // set the overall formula style
          this.formula_classification = d.results.formula_type

          this.category_tags = d.results.category ?? ''
          this.formula_keywords = d.results.subcategory?.split(',') ?? []

          /* eslint-disable no-empty */
          try {
            delete d.results.category
            delete d.results.subcategory
          } catch { }

          Object.assign(this.base_item, d.results)

          try {
            let p = d?.items?.formula_items ?? null
            if(p != null) {
              this.formulation.items = JSON.parse(p)
              let dbid = 0
              // pad all decimals with a leading 0
              this.formulation.items.forEach(e => {
                if (e.value < 1) { e.value = +e.value.toString().padStart(2, "0") }
                e.dbid = dbid++
              })
            }
          } catch (e) {
            console.error(e)
            this.formulation.items = []
          }
        })
        .catch(e => {
          console.log(e)
          // relase the lock 
          EventBus.$emit('error', {
            message: `Failed to load formula - Error 55`
          })
          this.panels = []
          this.results = []
          this.panel_disabled = true
        })
        .finally(() => {
          this.formulation.loading = false
        })
    },
    apply_padding(e) {
      if (e.target.value < 1.0) {
        e.target.value = +e.target.value.toString().padStart(3, "0")
      }
    },
    focus(evt) {
      // add feature to not call "save" if the user hasn't changed the text
      switch (evt.target.id) {
        case 'mixing':
          this.base_item.mixing_focus_copy = this.base_item.mixing
          break;
        case 'notes':
          this.base_item.properties_focus_copy = this.base_item.properties
          break;
        case 'properties':
          this.base_item.notes_focus_copy = this.base_item.notes
          break;
      }
    },
  }
}
</script>

<style scoped>
 .v-card { display: flex !important; flex-direction: column; }
 .v-card__text { flex-grow: 1; overflow: auto; }
 .v-expansion-panel--active > .v-expansion-panel-header { min-height:32px!important }
 .shrink { max-width: 100px!important; }
 .shrink-select { max-width: 150px!important; }
 .panel_shrink >>> .v-expansion-panel-content__wrap { padding: 8px!important;}
</style>

<style>
 .upper input { text-transform: uppercase; }
 .handle { cursor: move !important; cursor: -webkit-grabbing !important;}
</style>